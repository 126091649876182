import { EnumBase, EnumDetail, EnumWithColor, EnumWithColorAndIcon, EnumWithColorCss, EnumWithIcon } from "@hlcr/app/enum/EnumBase";
import { Enum } from "@hlcr/core/enum";
import { ButtonColor } from "@hlcr/mui/Button/style";
import { COLOR_DANGER, COLOR_GRAY, COLOR_INFO, COLOR_PRIMARY, COLOR_SUCCESS, COLOR_WARNING } from "@hlcr/mui/theme/hacking-lab.theme";
import { Assignment } from "@material-ui/icons";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import FlagIcon from "@material-ui/icons/Flag";
import QuestionMarkIcon from "@material-ui/icons/HelpOutline";
import QuestionAnswerIcon from "@material-ui/icons/QuestionAnswer";
import SendIcon from "@material-ui/icons/Send";
import StarIcon from "@material-ui/icons/Star";
import StarBorderIcon from "@material-ui/icons/StarBorder";
import StarHalfIcon from "@material-ui/icons/StarHalf";


import { QuizSolutionDto, Solution, SolutionComment } from "models/Solution";

// SolutionState
export enum SolutionState {
	INITIALISED = "INITIALISED",
	WAITING_FOR_TEACHER = "WAITING_FOR_TEACHER",
	GRADED = "GRADED",
	WAITING_FOR_PARTICIPANT = "WAITING_FOR_PARTICIPANT",
}

interface SolutionStateEnum extends EnumWithColorAndIcon {
}

const SOLUTION_STATES: EnumDetail<SolutionState, SolutionStateEnum> = {
	INITIALISED: {
		icon: ClearIcon,
		color: "info",
		iconColor: "blue",
		title: "solution.state.initialised",
	},
	WAITING_FOR_TEACHER: {
		icon: SendIcon,
		color: "primary",
		iconColor: "purple",
		title: "solution.state.waiting_for_teacher",
	},
	WAITING_FOR_PARTICIPANT: {
		icon: QuestionAnswerIcon,
		color: "warning",
		iconColor: "orange",
		title: "solution.state.waiting_for_participant",
	},
	GRADED: {
		icon: StarBorderIcon,
		color: "success",
		iconColor: "green",
		title: "solution.state.graded",
	},
};

// export under different name to prevent direct access to changed const
export const SolutionStates = SOLUTION_STATES;

export const getSolutionOrQuizSolutionState = (solution: any) => {
	// "answers" is a dirty hack, as there are different QuizSolutionDots in the project... differs if loaded as teacher or participant
	if ("quiz" in solution || "answers" in solution) {
		return getQuizSolutionState(solution.state);
	} else {
		return getSolutionState(solution.state);
	}
};

export const getSolutionState = (solutionState?: SolutionState) => {
	if (solutionState === undefined || !Object.keys(SOLUTION_STATES).includes(solutionState)) {
		console.debug(`invalid solution state: ${solutionState}`);
		return;
	}
	return SOLUTION_STATES[solutionState];
};

export const getQuizSolutionState = (quizSolutionState?: QuizSolutionState) => {
	if (quizSolutionState === undefined || !Object.keys(QUIZ_SOLUTION_STATES).includes(quizSolutionState)) {
		console.debug(`invalid solution state: ${quizSolutionState}`);
		return;
	}
	return QUIZ_SOLUTION_STATES[quizSolutionState];
};


// helper method to get any enum value in a type safe manner
const getEnumValue = <D extends EnumBase, E extends Enum>(values: EnumDetail<E, D>, key?: E) => {
	if (key === undefined || !Object.keys(values).includes(`${String(key)}`)) {
		return values.UNDEFINED;
	}
	return values[key];
};


// QuizSolutionState
// TODO: ALIGN LEGACY QUIZ SOLUTION STATES WITH SOLUTION STATES
export enum QuizSolutionState {
	SUBMITTED = "SUBMITTED",
	ANSWERED = "ANSWERED",
	REJECTED = "REJECTED",
	PARTIAL_POINTS = "PARTIAL_POINTS",
	FULL_POINTS = "FULL_POINTS",
	UNSOLVED = "UNSOLVED",
}

export const QUIZ_SOLUTION_STATES: EnumDetail<QuizSolutionState, SolutionStateEnum> = {
	UNSOLVED: {
		icon: ClearIcon,
		color: "grey",
		iconColor: "red",
		title: "solution.state.unsolved",
	},
	SUBMITTED: {
		icon: SendIcon,
		color: "primary",
		iconColor: "purple",
		title: "solution.state.submitted",
	},
	REJECTED: {
		icon: StarBorderIcon,
		color: "error",
		iconColor: "red",
		title: "solution.state.rejected",
	},
	PARTIAL_POINTS: {
		icon: StarHalfIcon,
		color: "warning",
		iconColor: "orange",
		title: "solution.state.partial_points",
	},
	FULL_POINTS: {
		icon: StarIcon,
		color: "success",
		iconColor: "green",
		title: "solution.state.full_points",
	},
	ANSWERED: {
		icon: QuestionAnswerIcon,
		color: "info",
		iconColor: "blue",
		title: "solution.state.answered",
	},
};

export const QuizSolutionStates = QUIZ_SOLUTION_STATES;

export enum SolutionSuccessState {
	COMPLETED = "COMPLETED",
	IMPROVABLE = "IMPROVABLE",
	REJECTED = "REJECTED",
	PENDING = "PENDING",
	UNDEFINED = "UNDEFINED",
}

export interface SolutionSuccessStateEnum extends EnumWithColor, EnumWithIcon, EnumWithColorCss {
	showProgress: boolean;
	importance: number;
}

const SOLUTION_SUCCESS_STATES: EnumDetail<SolutionSuccessState, SolutionSuccessStateEnum> = {
	[SolutionSuccessState.COMPLETED]: {
		icon: StarIcon,
		color: "success",
		colorCss: COLOR_SUCCESS,
		iconColor: "green",
		title: "solution_success.state.correct",
		showProgress: true,
		importance: 20,
	},
	[SolutionSuccessState.IMPROVABLE]: {
		icon: StarHalfIcon,
		color: "warning",
		colorCss: COLOR_WARNING,
		iconColor: "orange",
		title: "solution_success.state.partially_correct",
		showProgress: true,
		importance: 40,
	},
	[SolutionSuccessState.REJECTED]: {
		icon: StarBorderIcon,
		color: "error",
		colorCss: COLOR_DANGER,
		iconColor: "red",
		title: "solution_success.state.rejected",
		showProgress: true,
		importance: 30,
	},
	[SolutionSuccessState.PENDING]: {
		icon: StarBorderIcon,
		color: "primary",
		colorCss: COLOR_PRIMARY,
		iconColor: "purple",
		title: "solution_success.state.pending",
		showProgress: true,
		importance: 50,
	},
	[SolutionSuccessState.UNDEFINED]: {
		icon: QuestionMarkIcon,
		color: "gray",
		colorCss: COLOR_GRAY,
		iconColor: "gray",
		title: "solution_success.state.undefined",
		showProgress: false,
		importance: 10,
	},
};

export const getSolutionSuccessStateBySolution = (solution?: Solution) => {
	return getSolutionSuccessStateBySolutionInfo(solution?.state, solution?.improvable, solution?.points);
};

export const getSolutionSuccessStateBySolutionInfo = (solutionState?: SolutionState, solutionIsImprovable?: boolean, solutionPoints?: number) => {
	if (solutionState === undefined || !Object.keys(SOLUTION_STATES).includes(solutionState) || solutionState === SolutionState.INITIALISED) {
		return SolutionSuccessState.UNDEFINED;
	}

	if (solutionState === SolutionState.WAITING_FOR_TEACHER || solutionIsImprovable === undefined) {
		return SolutionSuccessState.PENDING;
	}

	if (solutionIsImprovable) {
		if (solutionPoints === 0) {
			return SolutionSuccessState.REJECTED;
		}
		return SolutionSuccessState.IMPROVABLE;
	}

	return SolutionSuccessState.COMPLETED;
};

export const getSolutionSuccessStateByQuizSolution = (quizSolution?: QuizSolutionDto) => {
	return getSolutionSuccessStateByQuizSolutionInfo(quizSolution?.state);
};
export const getSolutionSuccessStateByQuizSolutionInfo = (quizSolutionState?: QuizSolutionState) => {
	if (quizSolutionState === undefined || !Object.keys(QUIZ_SOLUTION_STATES).includes(quizSolutionState) || quizSolutionState === QuizSolutionState.UNSOLVED) {
		return SolutionSuccessState.UNDEFINED;
	}

	if (quizSolutionState === QuizSolutionState.SUBMITTED || quizSolutionState === QuizSolutionState.ANSWERED) {
		return SolutionSuccessState.PENDING;
	}

	if (quizSolutionState === QuizSolutionState.REJECTED) {
		return SolutionSuccessState.REJECTED;
	}

	if (quizSolutionState === QuizSolutionState.PARTIAL_POINTS) {
		return SolutionSuccessState.IMPROVABLE;
	}

	return SolutionSuccessState.COMPLETED;
};

export const getSolutionSuccessStateByTheory = (hasViewedTheory?: boolean) => {
	if (hasViewedTheory === undefined || !hasViewedTheory) {
		return SolutionSuccessState.UNDEFINED;
	}

	return SolutionSuccessState.COMPLETED;
};

export const getSolutionSuccessStateEnum = (solutionSuccessState?: SolutionSuccessState) => {
	if (!solutionSuccessState) {
		return SOLUTION_SUCCESS_STATES.UNDEFINED;
	}
	return getEnumValue(SOLUTION_SUCCESS_STATES, solutionSuccessState);
};

export const getSolutionSuccessStateByGrade = (grade: number) => {
	if (grade === 1) {
		return SOLUTION_SUCCESS_STATES.COMPLETED;
	} else if (grade === 0) {
		return SOLUTION_SUCCESS_STATES.REJECTED;
	} else {
		return SOLUTION_SUCCESS_STATES.IMPROVABLE;
	}
};


// SolutionCommentState
export enum SolutionCommentState {
	FLAG_SUBMITTED = "FLAG_SUBMITTED",
	WRITEUP_SUBMITTED = "WRITEUP_SUBMITTED",
	FLAG_AND_WRITEUP_SUBMITTED = "FLAG_AND_WRITEUP_SUBMITTED",
	COMMENTED = "COMMENTED",
	FLAG_REJECTED = "FLAG_REJECTED",
	FLAG_ACCEPTED = "FLAG_ACCEPTED",
	WRITEUP_REJECTED = "WRITEUP_REJECTED",
	WRITEUP_PARTIAL_POINTS = "WRITEUP_PARTIAL_POINTS",
	WRITEUP_FULL_POINTS = "WRITEUP_FULL_POINTS",
}

interface SolutionCommentStateEnum extends EnumWithColorAndIcon {
	isAnswer: boolean;
}

export const SOLUTION_COMMENT_STATES: EnumDetail<SolutionCommentState, SolutionCommentStateEnum> = {
	[SolutionCommentState.FLAG_SUBMITTED]: {
		icon: SendIcon,
		color: "primary",
		iconColor: "primary",
		title: "solution_comment.state.submitted.flag",
		isAnswer: false,
	},
	[SolutionCommentState.WRITEUP_SUBMITTED]: {
		icon: SendIcon,
		color: "primary",
		iconColor: "primary",
		title: "solution_comment.state.submitted.writeup",
		isAnswer: false,
	},
	[SolutionCommentState.FLAG_AND_WRITEUP_SUBMITTED]: {
		icon: SendIcon,
		color: "primary",
		iconColor: "primary",
		title: "solution_comment.state.submitted.flag_and_writeup",
		isAnswer: false,
	},
	[SolutionCommentState.COMMENTED]: {
		icon: QuestionAnswerIcon,
		color: "info",
		iconColor: "info",
		title: "solution_comment.state.commented",
		isAnswer: false,
	},
	[SolutionCommentState.FLAG_REJECTED]: {
		icon: FlagIcon,
		color: "error",
		iconColor: "danger",
		title: "solution_comment.state.flag.rejected",
		isAnswer: true,
	},
	[SolutionCommentState.FLAG_ACCEPTED]: {
		icon: FlagIcon,
		color: "success",
		iconColor: "success",
		title: "solution_comment.state.flag.accepted",
		isAnswer: true,
	},
	[SolutionCommentState.WRITEUP_REJECTED]: {
		icon: Assignment,
		color: "error",
		iconColor: "danger",
		title: "solution_comment.state.writeup.rejected",
		isAnswer: true,
	},
	[SolutionCommentState.WRITEUP_PARTIAL_POINTS]: {
		icon: Assignment,
		color: "warning",
		iconColor: "warning",
		title: "solution_comment.state.writeup.partial_points",
		isAnswer: true,
	},
	[SolutionCommentState.WRITEUP_FULL_POINTS]: {
		icon: Assignment,
		color: "success",
		iconColor: "success",
		title: "solution_comment.state.writeup.full_points",
		isAnswer: true,
	},
};

// TheorySolutionState
export enum TheorySolutionState {
	VISITED = "VISITED",
}

interface TheorySolutionStateEnum extends EnumWithColorAndIcon {
}

export const THEORY_SOLUTION_STATES: EnumDetail<TheorySolutionState, TheorySolutionStateEnum> = {
	VISITED: {
		icon: CheckIcon,
		color: "success",
		iconColor: "green",
		title: "solution.state.visited",
	},
};


// QuestionAnswerState
export enum QuestionAnswerState {
	UNSOLVED = "UNSOLVED",
	UNGRADED = "UNGRADED",
	SOLVED = "SOLVED",
	RIGHT = "RIGHT",
	WRONG = "WRONG",
}

interface QuestionAnswerStateEnum extends EnumWithColor {
	buttonColorActive: ButtonColor;
	buttonColorInactive: ButtonColor;
}

export const QUESTION_ANSWER_STATES: EnumDetail<QuestionAnswerState, QuestionAnswerStateEnum> = {
	UNSOLVED: {
		title: "question.answer.state.unsolved",
		color: COLOR_GRAY,
		buttonColorActive: "gray",
		buttonColorInactive: "gray",
	},
	UNGRADED: {
		title: "question.answer.state.ungraded",
		color: COLOR_PRIMARY,
		buttonColorActive: "primaryNoBackground",
		buttonColorInactive: "primary",
	},
	SOLVED: {
		title: "question.answer.state.solved",
		color: COLOR_INFO,
		buttonColorActive: "info",
		buttonColorInactive: "infoNoBackground",
	},
	RIGHT: {
		title: "question.answer.state.right",
		color: COLOR_SUCCESS,
		buttonColorActive: "success",
		buttonColorInactive: "successNoBackground",
	},
	WRONG: {
		title: "question.answer.state.wrong",
		color: COLOR_DANGER,
		buttonColorActive: "danger",
		buttonColorInactive: "dangerNoBackground",
	},
};

export const getSolutionCommentState = (solutionComment: SolutionComment) => {
	if (solutionComment.flagGrade === 1) {
		return SOLUTION_COMMENT_STATES.FLAG_ACCEPTED;
	} else if (solutionComment.flagGrade === 0) {
		return SOLUTION_COMMENT_STATES.FLAG_REJECTED;
	} else if (solutionComment.writeupGrade === 1){
		return SOLUTION_COMMENT_STATES.WRITEUP_FULL_POINTS;
	} else if (solutionComment.writeupGrade === 0){
		return SOLUTION_COMMENT_STATES.WRITEUP_REJECTED;
	} else if (solutionComment.writeupGrade !== undefined){
		return SOLUTION_COMMENT_STATES.WRITEUP_PARTIAL_POINTS;
	} else if (solutionComment.comment?.length){
		return SOLUTION_COMMENT_STATES.COMMENTED;
	} else if (solutionComment.flag?.length  && (solutionComment.writeup?.length || solutionComment.attachment !== undefined)){
		return SOLUTION_COMMENT_STATES.FLAG_AND_WRITEUP_SUBMITTED;
	} else if (solutionComment.flag?.length) {
		return SOLUTION_COMMENT_STATES.FLAG_SUBMITTED;
	} else {
		return SOLUTION_COMMENT_STATES.WRITEUP_SUBMITTED;
	}
};
