import { HttpMethod } from "@hlcr/core/enum/HttpMethod";

import { RemoteResource } from "models/RemoteResource";
import { DynamicRequestAction, RemoteResourceActionType } from "redux/actions";

const BASE_URL = "/api/user/events";

// @ts-ignore
export const fetchPreJoinInfo: DynamicRequestAction<string> = (token) => ({
	type: RemoteResourceActionType.REMOTE_RESOURCE_REQUEST,
	url: `${BASE_URL}/prejoin/${token}/`,
	resource: RemoteResource.EVENT_PRE_JOIN_INFO,
	method: HttpMethod.GET,
});
