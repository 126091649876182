import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import { withStyles } from "@material-ui/core/styles";
import HelpIcon from "@material-ui/icons/Help";
import cx from "classnames";
import PropTypes from "prop-types";
import React from "react";

import iconCardStyle from "@hlcr/mui/theme/material-dashboard-pro/jss/material-dashboard-pro-react/components/iconCardStyle.jsx";
import { NavLink } from "react-router-dom";

function IconCard({
	classes,
	title,
	titleLink,
	content,
	iconColor,
	category,
	footer,
	plain,
	customCardContentClass,
	helpLink,
	fluidWidth,
	icon: Icon,
	faIcon
}) {
	const cardClasses = cx(classes.card, {
		[classes.cardPlain]: plain,
		[classes.fluidWidth]: fluidWidth
	});
	const cardContentClasses = cx(classes.cardContent, customCardContentClass);
	return (
		<Card className={cardClasses}>
			<CardHeader
				classes={{
					root: cx(classes.cardHeader, classes[iconColor + "CardHeader"]),
					avatar: classes.cardAvatar
				}}
				avatar={
					Icon ? (
						<Icon className={classes.cardIcon} />
					) : (
						<FontAwesomeIcon className={classes.cardFaIcon} icon={faIcon} />
					)
				}
			/>
			<CardContent className={cardContentClasses}>
				<h4 className={classes.cardTitle}>
					{titleLink ? (<NavLink to={titleLink}>{title}</NavLink>) : title}
					{helpLink ? (
						<a href={helpLink} target="_blank" rel="noopener noreferrer">
							<HelpIcon className={classes.helpIcon} />
						</a>
					) : null}
					{category !== undefined ? (
						<small className={classes.cardCategory}>{category}</small>
					) : null}
				</h4>
				{content}
			</CardContent>
			{footer !== undefined ? (
				<div className={classes.cardFooter}>{footer}</div>
			) : null}
		</Card>
	);
}

IconCard.defaultProps = { iconColor: "purple" };

IconCard.propTypes = {
	classes: PropTypes.object.isRequired,
	icon: PropTypes.object,
	faIcon: PropTypes.string,
	iconColor: PropTypes.oneOf([
		"orange",
		"green",
		"red",
		"blue",
		"purple",
		"rose"
	]),
	helpLink: PropTypes.string,
	title: PropTypes.node,
	titleLink: PropTypes.string,
	category: PropTypes.node,
	content: PropTypes.any,
	footer: PropTypes.node,
	plain: PropTypes.bool,
	customCardContentClass: PropTypes.string,
	fluidWidth: PropTypes.bool
};

export default withStyles(iconCardStyle)(IconCard);
